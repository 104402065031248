import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as UUID, v4 } from 'uuid';

interface Notification {
  id: string;
  message: string;
  type: 'success' | 'error' | 'info' | 'warning';
}

interface NotificationsState {
  notifications: Notification[];
}

const initialState: NotificationsState = {
  notifications: [],
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Omit<Notification, 'id'>>) => {
      state.notifications.push({
        id: UUID(),
        ...action.payload,
      });
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter(n => n.id !== action.payload);
    },
    clearAllNotifications: (state) => {
      state.notifications = [];
    },
    apiRequestFailed: (state, action: PayloadAction<string>) => {
      state.notifications.push({
        id: UUID(),
        message: action.payload,
        type: 'error',
      });
    },
  },
});


export const selectNotifications = (state: { notifications: NotificationsState }) => state.notifications.notifications;

export const {
  addNotification,
  removeNotification,
  clearAllNotifications,
  apiRequestFailed,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
