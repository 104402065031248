import React, { useState, useEffect, useCallback } from 'react';
import { Container, Typography, List, ListItem, ListItemText, Paper, LinearProgress, Button, TextField, Slider, InputAdornment } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@web-client/state/store';
import {
  userSlice, getUserWallet, selectIsStripeAccountDisabled,
  setDefaultPaymentMethod,
  removePaymentMethod,
  selectIsSettingDefaultPaymentMethod,
  selectIsRemovingPaymentMethod,
  updateUserPricingSettings,
  getUserPricingSettings,
  selectPricingSettings,
  selectIsGettingPricingSettings,
} from '@web-client/state/slices/user';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentIcon from '@mui/icons-material/Payment';
import ListItemIcon from '@mui/material/ListItemIcon';

import ErrorIcon from '@mui/icons-material/Error';
import AddCardForm from '../payments/AddCard';
import { Elements } from '@stripe/react-stripe-js';
import Environment from '@web-client/services/environment';
import Stripe from 'stripe';
import { UserPricingSettings } from '@server/other/classes';


interface PaymentMethod {
  id: string;
  brand: string; // For non-card methods, this can be their "brand" or "name"
  last4: string;
  type: string; // "card", "bancontact", "sepa_debit", etc.
}

const DEFAULT_MONTHLY_PRICE = 9.67;
const DEFAULT_ANNUAL_DISCOUNT = 0;
const DEFAULT_CURRENCY = 'usd';

const stripePromise = Environment.getInstance().getStripePromise();

export default function Wallet() {
  const dispatch = useAppDispatch();
  const wallet = useAppSelector(userSlice.selectors.selectWallet);
  const isWalletLoading = useAppSelector(userSlice.selectors.isGettingWallet);
  const isStripeAccountDisabled = useAppSelector(selectIsStripeAccountDisabled);
  const isSettingDefaultPaymentMethod = useAppSelector(selectIsSettingDefaultPaymentMethod);
  const isRemovingPaymentMethod = useAppSelector(selectIsRemovingPaymentMethod);
  const isGettingPricingSettings = useAppSelector(selectIsGettingPricingSettings);

  const pricingSettings = useAppSelector(selectPricingSettings);

  const [monthlyPrice, setMonthlyPrice] =
  useState<number>(DEFAULT_MONTHLY_PRICE);
  const [annualDiscount, setAnnualDiscount] =
    useState<number>(DEFAULT_ANNUAL_DISCOUNT);
  const [currency, setCurrency] = useState<string>(DEFAULT_CURRENCY);

  const handleSubscriptionUpdate = () => {
    dispatch(updateUserPricingSettings({
      annualSubscriptionDiscountPercentage: annualDiscount,
      monthlySubscriptionPrice: Number(monthlyPrice),
      monthlySubscriptionCurrency: currency,
    }));
  };

  const updateSettings = useCallback(() => {
    const {
      monthlySubscriptionPrice = DEFAULT_MONTHLY_PRICE,
      annualSubscriptionDiscountPercentage = DEFAULT_ANNUAL_DISCOUNT,
    } = pricingSettings ?? {};
    setMonthlyPrice(monthlySubscriptionPrice);
    setAnnualDiscount(annualSubscriptionDiscountPercentage);
  }, [pricingSettings]);

  useEffect(() => {
    updateSettings();
  }, [pricingSettings, updateSettings]);

  useEffect(() => {
    dispatch(getUserPricingSettings());
    dispatch(getUserWallet());
  }, []);

  const paymentMethods = wallet?.paymentMethods ?? [];

  const stripeTodoList = [
    ...wallet?.stripeConnectAccount?.requirements?.past_due ?? [],
    ...wallet?.stripeConnectAccount?.requirements?.currently_due ?? [],
  ];

  return (
    <Container sx={{ py: 4 }}>
    <Typography variant="h1" gutterBottom>Wallet</Typography>
    <Container sx={{ py: 2 }}>
    <Typography variant="h4" gutterBottom>Payment Methods</Typography>
    <Paper sx={{ p: 2 }}>
    <Paper>
    <Elements stripe={stripePromise}>
    <AddCardForm onCardAdded={() => {
      dispatch(getUserWallet());
    }}/>
    </Elements>
    </Paper>

    <List>
    {isWalletLoading && (<LinearProgress />)}
    {paymentMethods.map((method: Stripe.PaymentMethod) => {
      let primaryText = '';
      let secondaryText = '';
      let IconComponent = null;

      if (method.type === 'card') {
        primaryText = `${method.card?.brand?.toUpperCase()} ending in ${method.card?.last4}`;
        secondaryText = 'Credit/Debit Card';
        IconComponent = <CreditCardIcon />;
      } else if (method.type === 'sepa_debit') {
        primaryText = `Bank Account ending in ${method.sepa_debit?.last4}`;
        secondaryText = 'SEPA Debit';
        IconComponent = <AccountBalanceIcon />;
      } else {
        primaryText = `Payment method: ${method.type}`;
        IconComponent = <PaymentIcon />;
      }

      return (
        <ListItem key={method.id}>
        <ListItemIcon>{IconComponent}</ListItemIcon>
        <ListItemText primary={primaryText} secondary={secondaryText} />


        <Button
        variant="outlined"

        disabled={method.id === wallet?.defaultPaymentMethodId || isSettingDefaultPaymentMethod}
        sx={{
          mr: 2,
          visibility: method.id === wallet?.defaultPaymentMethodId ? 'hidden' : 'visible',
        }}
        onClick={() => {
          dispatch(setDefaultPaymentMethod({
            paymentMethodId: method.id,
          })).then(() => {
            dispatch(getUserWallet());
          });
        }}
        >
        {method.id === wallet?.defaultPaymentMethodId ? 'Default' : 'Set Default'}
        </Button>


        <Button variant="outlined" color="error"
        sx={{ visibility: method.id === wallet?.defaultPaymentMethodId ? 'hidden' : 'visible' }}
        disabled={method.id === wallet?.defaultPaymentMethodId || paymentMethods.length === 1 || isRemovingPaymentMethod}
        onClick={() => {
          dispatch(removePaymentMethod({
            paymentMethodId: method.id,
          })).then(() => {
            dispatch(getUserWallet());
          });
        }}>
        Remove
        </Button>
        </ListItem>
      );
    })}
    </List>
    </Paper>
    </Container>
    <Container sx={{ py: 2 }}>
    <Typography variant="h4" gutterBottom>
    Monetization
    </Typography>
    {isWalletLoading && (<LinearProgress />)}
    {(!isWalletLoading && !isStripeAccountDisabled) && (
      <Paper sx={{ p: 2 }}>
      <Typography variant="body1" gutterBottom>
      <CheckCircleIcon color="success" sx={{ marginRight: '8px' }} />
      Your Stripe Account is active.
      </Typography>
      <Paper sx={{ p: 10, m: 2 }}>
      <Typography variant="h5" gutterBottom>
      Subscription Settings
      </Typography>
      <Typography gutterBottom>
      Set the monthly subscription price and annual discount for your subscribers.<br/>
      Users who subscribe will be able to access all of your content while they are subscribed.
      </Typography>
      {isGettingPricingSettings && (<LinearProgress />)}
      {!isGettingPricingSettings && (
        <>
        <TextField
        label="Monthly Subscription Price (USD)"
        type="number"
        value={monthlyPrice}
        onChange={(e) => {
          const newPrice = e.target.value;
          const cleanPrice = newPrice.replace(/[^0-9.]/g, '');
          const formattedPrice = Number(parseFloat(cleanPrice).toFixed(2));
          setMonthlyPrice(formattedPrice);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
            per month
            </InputAdornment>
          ),
        }}
        fullWidth
        margin="normal"
        />

        <Typography gutterBottom sx={{ mt: 2 }}>
        Annual Subscription Discount (%):
        {annualDiscount}%
        </Typography>
        <Slider
        value={annualDiscount}
        onChange={(e, newValue) => setAnnualDiscount(newValue as number)}
        min={0}
        max={50}
        step={1}
        marks
        valueLabelDisplay="auto"
        />

        <Button
        variant="contained"
        color="primary"
        onClick={handleSubscriptionUpdate}
        sx={{ mt: 2 }}
        >
        Save Subscription Settings
        </Button>
        </>
      )}

      </Paper>
      </Paper>
    )}
    {(!isWalletLoading && isStripeAccountDisabled) && (
      <>
      <Paper sx={{ p: 2, my: 2 }}>
      <p>
      <ErrorIcon color="error" sx={{ marginRight: '8px' }} />
      Your Stripe Connect account needs attention.  Please fix the issues.
      Until the issues are resolved, you will not be able to receive payments.
      </p>
      <Button>
      {wallet?.stripeOnboardingLink?.url ? (
        <a href={wallet.stripeOnboardingLink?.url} target="_blank" rel="noreferrer">
        Complete Stripe Onboarding
        </a>
      ) : (
        <a href={`https://dashboard.stripe.com/connect/accounts/${wallet?.stripeConnectAccount?.id}`} target="_blank" rel="noreferrer">
        Go to Stripe
        </a>

      )}
      </Button>
      </Paper>
      </>
    )}
    </Container>
    </Container>
  );
}