import {
  Card, Grid, List, ListItem, ListItemButton, ListItemText, Typography, Box, Divider, Collapse,
  LinearProgress,
  Button,
  Stack,
  Avatar
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ThemedPaper from '../core/ThemedPaper';
import VideoPlayer from '../core/VideoPlayer';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@web-client/state/store';
import { getProductbyId, selectProduct } from '@web-client/state/slices/products';
import { ProductDatum } from '@web-client/services/ApiService';
import Paths from '@web-client/paths';


const formatUsd = (amount: number): string =>
  new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
}).format(amount);


function Paywall(props: {
  product: ProductDatum,
}) {
  const { product } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const creatorId = product.creator?.uid ?? product.creator?.email as string;
  return (
    <Stack
    direction="column"
    justifyContent="center"
    alignItems="center"
    spacing={2}
    sx={{
      width: 1,
      height: 1,
      position: 'absolute',
      top: 0,
      left: 0,
      backdropFilter: 'blur(5px)',
      zIndex: 1,
      color: theme.palette.text.primary,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: `radial-gradient(circle, ` +
        `${theme.palette.background.default}, ` +
        `transparent)`,
        zIndex: -1,
        backdropFilter: 'blur(10px)',
        opacity: 1,
      },
    }}
    >
    <Typography
    variant="h4"
    sx={{
      position: 'relative',
      zIndex: 2,
      textShadow: `0 0 10px rgba(0, 0, 0, 0.5)`,
      background: `rgba(${theme.palette.background.paper}, 0.7)`,
      padding: theme.spacing(1, 2),
      borderRadius: theme.shape.borderRadius,
      userSelect: 'none'
    }}
    >
    Get Access Now!
    </Typography>
    {/* <Button
      variant="contained"
      color="primary"
      sx={{
      position: 'relative',
      zIndex: 2,
      }}
      onClick={() => {
      navigate(Paths.SUBSCRIBE.replace(':userId', creatorId));
      }}
      >
      Subscribe
      </Button> */}
      <Button
      variant="contained"
      color="secondary"
      sx={{
        position: 'relative',
        zIndex: 2,
      }}
      onClick={() => {
        if (product.id) {
          navigate(Paths.PRODUCT_CHECKOUT
            .replace(':productId', product.id)
            .replace(':action', 'purchase'));
          }
        }}
        >
        Buy Course for {formatUsd(product.coursePrice ?? 0)}
        </Button>
        {/* <pre>
          {JSON.stringify(product.creator, null, 4)}
          </pre> */}
          </Stack>
        );
      }

      const ProductCard = (params: { product: ProductDatum }) => {
        const { product } = params;
        const theme = useTheme();
        const navigate = useNavigate();
        const [activeItem, setActiveItem] = useState<[number, number]>([0, 0]);
        const [expandedLists, setExpandedLists] = useState<number[]>([]);

        const handleListToggle = (index: number) => {
          setExpandedLists((prev) =>
            prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
        );
      };

      const handleItemClick = (groupIndex: number, itemIndex: number) => {
        setActiveItem([groupIndex, itemIndex]);
        if (!expandedLists.includes(groupIndex)) {
          setExpandedLists((prev) => [...prev, groupIndex]);
        }
      };

      const activeContent = product.content[activeItem[0]]?.content[activeItem[1]] ?? null;

      const isPaid = product.isPurchased;
      const isOwner = product.isOwner;

      const videoSources = [
        {
          src: activeContent?.value,
          type: 'video/mp4',
        },
      ];

      return (
        <Card variant="outlined" sx={{ marginBottom: 1 }}>

        <Grid container spacing={2} sx={{ position: 'relative', minHeight: 'calc(100vh - 200px)' }}>
        <Grid item xs={12} md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
        <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 2,
          borderBottom: `1px solid ${theme.palette.divider}`,
          mb: 2,
        }}
        >

        <Avatar
        src={product.creator?.photoURL}
        alt={product.creator?.displayName || product.creator?.email || 'Author'}
        sx={{ width: 40, height: 40, ml: 2 }}
        >
        {!(product.creator?.photoURL) &&
          (product.creator?.displayName?.[0] ||
            product.creator?.email?.[0] ||
            'A')}
            </Avatar>
            <Typography variant="subtitle1">
            {product.creator?.displayName || product.creator?.email || 'Unknown Author'}

            </Typography>
            <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              const creatorId = product.creator?.uid || product.creator?.email;
              if (creatorId) {
                navigate(Paths.SUBSCRIBE.replace(':userId', creatorId));
              }
            }}
            >
            Subscribe
            </Button>
            </Box>
            <List sx={{ width: '100%' }}>
            {product.content.map((contentGroup, groupIndex) => (
              <Box key={groupIndex} sx={{ mb: 1 }}>
              <ListItem disablePadding>
              <ListItemButton
              onClick={() => handleListToggle(groupIndex)}
              sx={{
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 1,
                transition: 'background 0.3s',
              }}
              >
              <AutoStoriesIcon
              sx={{
                mr: 3,
                ml: 2,
                height: 30,
                width: 30,
                transform: expandedLists.includes(groupIndex)
                ? 'rotate(90deg)'
                : 'rotate(0deg)',
                transition: 'transform 0.2s',
              }}
              />
              <ListItemText primary={contentGroup.title} />
              </ListItemButton>
              </ListItem>
              <Collapse in={expandedLists.includes(groupIndex)} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{  }}>
              {contentGroup.content.map((content, index) => (
                <ListItem key={index} disablePadding>
                <ListItemButton
                selected={activeItem[0] === groupIndex && activeItem[1] === index}
                onClick={() => handleItemClick(groupIndex, index)}
                >
                {index % 2 === 0 ? (
                  <CheckBoxIcon sx={{ mr: 3, opacity: 0.5 }} />
                ) : (
                  <CheckBoxOutlineBlankIcon sx={{ mr: 3, opacity: 0.5 }} />
                )}
                <ListItemText primary={content.title} />
                </ListItemButton>
                </ListItem>
              ))}
              </List>
              </Collapse>
              </Box>
            ))}
            </List>
            </Grid>
            <Grid item xs={12} md={9} sx={{ paddingLeft: { md: '0 !important' } }}>
            {activeContent && (
              <Box sx={{ position: 'relative' }}>
              <ThemedPaper>
              <Typography variant="h5" sx={{ p: 2 }}>
              {activeContent?.title}
              </Typography>
              </ThemedPaper>
              <Box sx={{ position: 'relative', minHeight: 'calc(50vh)' }}>
              {(!isPaid && !isOwner) && <Paywall product={product} />}
              {activeContent?.type === 'video' && (
                <Box sx={{ position: 'relative' }}>
                <VideoPlayer sources={videoSources} onReady={() => { }} />
                </Box>
              )}
              </Box>
              </Box>
            )}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <List sx={{ width: '100%' }}>
            {product.content.map((contentGroup, groupIndex) => (
              <Box key={groupIndex} sx={{ mb: 1 }}>
              <ListItem disablePadding>
              <ListItemButton
              onClick={() => handleListToggle(groupIndex)}
              sx={{
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 1,
              }}
              >
              <AutoStoriesIcon
              sx={{
                mr: 3,
                ml: 2,
                height: 30,
                width: 30,
                transform: expandedLists.includes(groupIndex)
                ? 'rotate(90deg)'
                : 'rotate(0deg)',
                transition: 'transform 0.2s',
              }}
              />
              <ListItemText primary={contentGroup.title} />
              </ListItemButton>
              </ListItem>
              <Collapse in={expandedLists.includes(groupIndex)} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{ pl: 4 }}>
              {contentGroup.content.map((content, index) => (
                <ListItem key={index} disablePadding>
                <ListItemButton
                selected={activeItem[0] === groupIndex && activeItem[1] === index}
                onClick={() => handleItemClick(groupIndex, index)}
                >
                {index % 2 === 0 ? (
                  <CheckBoxIcon sx={{ mr: 3, opacity: 0.5 }} />
                ) : (
                  <CheckBoxOutlineBlankIcon sx={{ mr: 3, opacity: 0.5 }} />
                )}
                <ListItemText primary={content.title} />
                </ListItemButton>
                </ListItem>
              ))}
              </List>
              </Collapse>
              </Box>
            ))}
            </List>
            </Box>

            </Grid>
            </Grid>
            </Card>
          );
        };


        export default function ProductDetail() {
          const dispatch = useAppDispatch();
          const product = useAppSelector(selectProduct);
          const params = useParams();

          const { productId } = params;

          useEffect(() => {
            if (productId) {
              dispatch(getProductbyId({ productId }));
            }
          }, [dispatch, productId]);

          if (!product) {
            return (
              <Card variant="outlined" sx={{ marginBottom: 1 }}>
              <Box sx={{ p: 2 }}>
              <LinearProgress variant="indeterminate" />
              </Box>
              </Card>
            );
          }

          return (
            <ThemedPaper elevation={2} sx={{ m: 5 }}>
            <ProductCard product={product} />
            </ThemedPaper>
          );
        }