import { CSSProperties, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable, DraggableLocation, DraggableStyle, DropResult } from '@hello-pangea/dnd';
import { Box, Button, Collapse, Divider, IconButton, ListItem, ListItemButton, ListItemIcon, Typography, useTheme } from '@mui/material';
import { Clear, Add, ExpandCircleDown, DragHandle,  ArrowUpward, ArrowDownward, LibraryAdd } from '@mui/icons-material';
import ThemedPaper from './ThemedPaper';
import { v4 as uuid } from 'uuid';
import { AnyObject } from '../../types';
import EditableLabel from './EditableLabel';
import { debounce, isEqual } from 'lodash';
import { diffString, diff } from 'json-diff';

/**
* Size of each grid item in height (pixels).
*/
const GRID_SIZE = 5;
const ACTIVE_ITEM_BGCOLOR = 'lightgreen';
const ACTIVE_GROUP_BGCOLOR = 'lightblue';

enum Direction {
  UP = -1,
  DOWN = 1,
}

type ContentData = {
  id?: string | number,
  title: string,
  children?: ContentData[],
  [x: string|number|symbol]: any,
}

export type ActiveItem = {
  groupIndex: number,
  itemIndex: number,
};

export type KeyMapping = [string, string][];

/**
* Generates a unique ID for each sortable item.  This is used by the drag and
* drop library to uniquely identify each item during sorting.
*/
function createUniqueId(offset = 0): string {
  return `item-${(offset ?? 0)}-${new Date().getTime()}-${uuid()}`;
}
/**
* Creates a new, blank unit item.
*/
function createNewDatum(offset = 0, hasChildren = false): ContentData {
  const newData: ContentData = {
    id: createUniqueId(offset),
    title: '[Untitled]',
  };
  if (hasChildren) {
    newData.children = [];
  }
  return newData;
}

export function transformKeys(
  state: AnyObject,
  keyMapping: KeyMapping,
  reverse = false,
): { [x: string | number | symbol]: any } {
  if (reverse) {
    const newMapping = keyMapping.map((mapItem) => {
      return [mapItem[1], mapItem[0]] as [string, string];
    });

    return transformKeys(state, newMapping);
  }
  const clonedState = structuredClone(state);
  for (let [sourceKey, targetKey] of keyMapping) {
    if (!(sourceKey in clonedState)) {
      // Delete values if not existing.
      delete clonedState[targetKey];
      continue;
    }
    const tempValue = clonedState[sourceKey];
    if (Array.isArray(tempValue)) {
      clonedState[targetKey] = tempValue
      .filter(v => !!v).map(item => transformKeys(item, keyMapping));
      // }
      // else if (typeof tempValue === 'undefined') {
      // NOTE(beneedictchen): Do nothing because the value is `undefined`.
      // We will just allow the original key to be deleted.
      // If this causes bugs in the future we can just remove this.
    } else {
      clonedState[targetKey] = tempValue;
    }
    delete clonedState[sourceKey];
  }
  return clonedState;
}

/**
* Takes a given tree that may or may not have an ID property and assigns a
* unique ID for the entire tree (and all children involved).
*/
function assignIdsToData(tree: ContentData, offset=0) {
  if (!tree.id) {
    tree.id = createUniqueId(offset);
  }
  if (tree.children && tree.children.length > 0) {
    tree.children = tree.children?.map((child) => {
      return assignIdsToData(child, offset + 1);
    }) ?? [];
  }
  return tree;
}

/**
* Takes an item within a group and reorders it inside that same group.
* This is for reorderings that are not cross-group, but rather within the
* exact same group, but just changing order.
*/
function reorder(
  state: ContentData,
  groupIndex: number,
  startItemIndex: number,
  endItemIndex: number,
): ContentData {
  const stateClone = structuredClone(state);
  stateClone.children = stateClone.children ?? [];
  const grandChildItem = stateClone.children[groupIndex];
  if (!grandChildItem) {
    throw new Error('Cannot find target to reshuffle items.');
  }
  grandChildItem.children = grandChildItem.children ?? [];
  const [removedItem] = grandChildItem.children.splice(startItemIndex, 1);
  grandChildItem.children.splice(endItemIndex, 0, removedItem);
  stateClone.children[groupIndex] = grandChildItem;
  return stateClone;
};

/**
* Moves items across different groups (from one list to another).
*/
function move(
  state: ContentData,
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation,
): ContentData {
  const newState = structuredClone(state);
  const sourceGroupIdx = +droppableSource.droppableId;
  const destGroupIdx = +droppableDestination.droppableId;
  const sourceItemIdx = droppableSource.index;
  const destItemIdx = droppableDestination.index;
  newState.children = newState.children ?? [];
  const sourceItem = newState.children[sourceGroupIdx] as ContentData;
  sourceItem.children = sourceItem.children || [];
  const [removedItem] = (sourceItem.children as ContentData[])
  .splice(sourceItemIdx, 1);
  const destItem = newState.children[destGroupIdx] as ContentData;
  destItem.children = destItem.children || [];
  (destItem.children as ContentData[])
  .splice(destItemIdx, 0, removedItem);
  // Remove empty groups.
  // newState.children = newState.children.filter((groups: ContentData) => {
  //   return groups.children?.length;
  // });
  return newState;
}

/**
*Deletes an item from a given group/list, given the indices for both.
*/
function deleteItem(
  state: ContentData, groupIndex: number, itemIndex: number,
): ContentData {
  const newState = structuredClone(state);
  newState.children = newState.children ?? [];
  const groupChildren = newState.children[groupIndex].children as ContentData[];
  groupChildren.splice(itemIndex, 1);
  // newState.children = newState.children.filter((group: ContentData) => group.children?.length);
  return newState;
}


function deleteGroup(state: ContentData, groupIndex: number) {
  const newState = structuredClone(state);
  let children = (newState.children as ContentData[]);
  children.splice(groupIndex, 1);
  children = children.filter((v => !!v));
  newState.children = children;
  return newState;
}

function shiftGroup(
  state: ContentData,
  direction: Direction,
  groupIndex: number,
  collapsedGroups: Set<number>,
) {

  const newState = structuredClone(state);
  const children = (newState.children as ContentData[]);
  const [removedItem] = children.splice(groupIndex, 1);
  let newIndex = Math.max(0, groupIndex + direction);
  newIndex = Math.min(newIndex, children.length);
  children.splice(newIndex, 0, removedItem);
  newState.children = children;
  const newCollapsedGroups = new Set(Array.from(collapsedGroups));

  const isSrcGroupCollapsed = newCollapsedGroups.has(groupIndex);
  const isTargetGroupCollapsed = newCollapsedGroups.has(newIndex);

  if (isSrcGroupCollapsed && !isTargetGroupCollapsed) {
    // Handle case where current group is collapsed and is moving up.
    newCollapsedGroups.delete(groupIndex);
    newCollapsedGroups.add(newIndex);
  } else if (!isSrcGroupCollapsed && isTargetGroupCollapsed) {
    // Handle case where current group is open and target is collapsed.
    newCollapsedGroups.delete(newIndex);
    newCollapsedGroups.add(groupIndex);
  }

  return {newState, newCollapsedGroups};
}

/**
* Adds a new group/list.
*/
function addNewGroup(state: ContentData): ContentData {
  const newState = structuredClone(state);
  newState.children = newState.children ?? [];
  const lastChild = newState.children.at(-1);
  if (!lastChild) {
    newState.children.push(createNewDatum());
  } else {
    lastChild.children = lastChild.children ?? [];
    lastChild.children.push(createNewDatum())
  }
  return newState;
}

/**
* Helper to get CSS styles for each draggable item based on its current state
* of being dragged vs. not dragged.
*/
function getItemStyle (
  isDragging: boolean,
  draggableStyle: DraggableStyle | CSSProperties,
  activeColor: string = ACTIVE_ITEM_BGCOLOR,
) {
  return {
    userSelect: 'none' as any,
    margin: `0 0 ${GRID_SIZE}px 0`,
    background: isDragging ? activeColor : 'inherit',
    ...draggableStyle
  };
};

/**
* Determines the CSS styling for the group/list being dragged into based on
* whether or not the user is dragging an item inside of it.
*/
const getGroupStyle = (
  isDraggingOver: boolean,
  activeColor: string = ACTIVE_GROUP_BGCOLOR,
) => ({
  background: isDraggingOver ? activeColor : 'inherit',
  padding: GRID_SIZE,
});

function UnitItem(props: {
  state: ContentData,
  groupIndex: number,
  index: number,
  item: ContentData,
  isSortingDisabled?: boolean,
  isActive?: boolean,
  onChange: (newState: ContentData) => void,
  onItemClick?: (state: ContentData, groupIndex: number, index: number) => void,
}) {
  const theme = useTheme();
  const {
    item,
    state,
    groupIndex,
    index,
    isSortingDisabled = false,
    isActive = false,
    onChange = () => { },
    onItemClick = () => {},
  } = props;
  return (
    <ListItem
    sx={{
      backgroundColor: isActive ? theme.palette.info.main : null,
    }}
    secondaryAction={!isSortingDisabled && (
      <IconButton
      onClick={() => {
        const newState = deleteItem(state, groupIndex, index);
        onChange(newState);
      }}
      >
      <Clear />
      </IconButton>
    )}>

    <ListItemIcon>
    {!isSortingDisabled && (
      <DragHandle />
    )}
    </ListItemIcon>
    <ListItemButton sx={{
      marginRight: 2,
    }}
    onClick={() => onItemClick(state, groupIndex, index)}>
    <Typography noWrap sx={{
      overflow: 'hidden', textOverflow: 'ellipsis',
      fontWeight: isActive ? 'bold' : 'inherit',
    }}>
    {item.title}
    </Typography>
    </ListItemButton>
    </ListItem>
  );
}

type SortableListProps = {
  initialState?: AnyObject;
  keyMapping: KeyMapping,
  isSortingDisabled?: boolean,
  activeItem?: ActiveItem | null | undefined,
  onChange?: (state: AnyObject) => void,
  onItemClick?: (state: AnyObject, groupIndex: number, index: number) => void,
  onGroupClick?: (state: AnyObject, groupIndex: number) => void,
};

export default function SortableList(props: SortableListProps) {

  const theme = useTheme();
  const colorA = theme.palette.secondary.main;
  const colorB = theme.palette.success.main;

  let { initialState } = props;
  const {
    isSortingDisabled = false,
    keyMapping = [],
    activeItem = null,
    onChange = () => { },
    onItemClick = () => { },
    onGroupClick = () => {},
  } = props;
  if (initialState == null) {
    initialState = createNewDatum(0, true);
  }
  const wrangledState = transformKeys(initialState, keyMapping);
  const wrangledStateWithIds = assignIdsToData(wrangledState as ContentData);
  const [state, setState] = useState(wrangledStateWithIds);
  const [collapsedGroups, setCollapsedGroups] = useState(new Set<number>([]));

  const handleChange = (newState: ContentData) => {
    setState(newState);
    const unwrangledData = transformKeys(newState, keyMapping, true);
    onChange(unwrangledData);
  }

  const unwrangledState = transformKeys(state, keyMapping, true);
  const isDirty = !isEqual(unwrangledState, initialState);
  // console.log(diffString(initialState, unwrangledState));
  // console.log('>>>',{ state, keyMapping, unwrangledState, initialState})

  useEffect(() => {
    if (isDirty) {
      setTimeout(() => setState(wrangledStateWithIds));
    }
  }, [initialState])

  function onDragEnd(result: DropResult) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const srcGroupIndex = +source.droppableId;
    const dstGroupIndex = +destination.droppableId;

    if (srcGroupIndex === dstGroupIndex) {
      const newState = reorder(state, srcGroupIndex, source.index, destination.index);
      handleChange(newState);
    } else {
      if (!state.children || !Array.isArray(state.children)) {
        return;
      }
      const newState = move(state, source, destination);
      handleChange(newState);
    }
  }

  return (
    <Box sx={{ p: 2 }}>
    {isDirty ? 'DIRTY' : 'CLEAN'}
    {!isSortingDisabled && (
      <Box sx={{
        marginBottom: 3,
        p: 1,
        border: `1px solid ${theme.palette.divider}`
      }}>
      <Button
      variant='outlined'
      sx={{
        marginRight: {
          xs: 0,
          md: 2,
        },
        width: {
          xs: '100%',
          md: '100%',
        },
      }}
      onClick={() => {
        handleChange({ ...state, children: [...state.children ?? [], createNewDatum()]});
      }}
      >
      <LibraryAdd sx={{marginRight: 2}} />  Add new group
      </Button>
      <Button
      variant='outlined'
      sx={{
        width: {
          xs: '100%',
          md: '100%',
        },
      }}
      onClick={() => {
        const newState = addNewGroup(state);
        handleChange(newState);
      }}
      >
      <Add sx={{marginRight: 2}}/> Add new item
      </Button>
      </Box>
    )}
    <ThemedPaper>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
    <DragDropContext onDragEnd={onDragEnd}>
    {state.children?.map((groupItem, groupIndex) => {
      const isGroupCollapsed = collapsedGroups.has(groupIndex);
      const isLastGroup = groupIndex === ((state.children?.length ?? 1) - 1)
      return (
        <Droppable
        key={groupIndex}
        droppableId={`${groupIndex}`}
        isDropDisabled={isSortingDisabled}
        >
        {(provided, snapshot) => (
          <div
          ref={provided.innerRef}
          style={getGroupStyle(snapshot.isDraggingOver, colorA)}
          {...provided.droppableProps}>
          <ThemedPaper elevation={5}>
          <ListItem
          secondaryAction={!isSortingDisabled && (
            <>
            <IconButton disabled={groupIndex === 0} onClick={() => {
              const {
                newState,
                newCollapsedGroups,
              } = shiftGroup(state, Direction.UP, groupIndex, collapsedGroups);
              handleChange(newState);
              setCollapsedGroups(new Set(Array.from(newCollapsedGroups)));
            }}><ArrowUpward /></IconButton>
            <IconButton disabled={isLastGroup} onClick={() => {
              const {
                newState,
                newCollapsedGroups,
              } = shiftGroup(state, Direction.DOWN, groupIndex, collapsedGroups);
              handleChange(newState);
              setCollapsedGroups(newCollapsedGroups);
            }}><ArrowDownward /></IconButton>

            <IconButton onClick={() => {
              handleChange(deleteGroup(state, groupIndex))
            }}><Clear /></IconButton>
            </>
          )}>
          <ListItemIcon onClick={() => {
            // Handle expand and collapse of group items when user
            // clicks on the icon.
            const newCollapsedGroups = new Set(Array.from(collapsedGroups));
            if (isGroupCollapsed) {
              newCollapsedGroups.delete(groupIndex)
              setCollapsedGroups(newCollapsedGroups);
            } else {
              newCollapsedGroups.add(groupIndex)
              setCollapsedGroups(newCollapsedGroups);
            }
          }}>
          <ExpandCircleDown sx={{
            transform: !isGroupCollapsed ? 'inherit' : 'rotate(-90deg)',
            transition: 'all 1s',
          }} />
          </ListItemIcon>
          <ListItemButton
          sx={{ marginRight: 12 }}
          onClick={() => onGroupClick(state, groupIndex)}
          >
          {/* <Typography noWrap sx={{
            overflow: 'hidden', textOverflow: 'ellipsis',
            }}> */}
            {isSortingDisabled ? (<Typography>{groupItem.title}</Typography>) : (<EditableLabel
              value={groupItem.title}
              onChange={(newTitle) => {
                const stateClone = structuredClone(state);
                if (stateClone.children) {
                  stateClone.children[groupIndex].title = newTitle;
                }
                handleChange(stateClone);
              }}
              />)}

              {/* </Typography> */}
              </ListItemButton>
              </ListItem>
              </ThemedPaper>
              <Divider />
              <Collapse in={!collapsedGroups.has(groupIndex)} timeout='auto' unmountOnExit>
              {groupItem.children?.map((item: ContentData, index: number) => {
                const isActiveItem = (activeItem?.groupIndex === groupIndex && activeItem.itemIndex === index)
                return (
                  <Draggable
                  key={item.id}
                  draggableId={String(item.id)}
                  index={index}
                  isDragDisabled={isSortingDisabled}
                  >
                  {(provided, snapshot) => (
                    <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style && getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                      colorB,
                    )}
                    >
                    <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around'
                    }}
                    >
                    <UnitItem groupIndex={groupIndex}
                    isActive={isActiveItem}
                    index={index}
                    item={item}
                    state={state}
                    isSortingDisabled={isSortingDisabled}
                    onChange={(newState) => handleChange(newState)}
                    onItemClick={(newState, groupIndex, itemIndex) => {
                      const unwrangledData = transformKeys(newState, keyMapping, true);
                      onItemClick(unwrangledData, groupIndex, itemIndex);
                    }}
                    />
                    </div>
                    </div>
                  )}
                  </Draggable>
                )})}
                </Collapse>
                {provided.placeholder}
                </div>
              )}
              </Droppable>
            )
          })}
          </DragDropContext>
          </div>
          </ThemedPaper>
          </Box>
        );
      }