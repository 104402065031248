import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Container, Button, Typography, CircularProgress, Box } from '@mui/material';
import ApiService from '@web-client/services/ApiService';
import { Api } from '@mui/icons-material';
import { addNotification } from '@web-client/state/slices/notifications';
import { useAppDispatch } from '@web-client/state/store';



type AddCardFormProps = {
  onCardAdded: () => void;
};

const AddCardForm = ({
  onCardAdded = () => {},
}: AddCardFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) return;

    try {
      // Step 1: Get SetupIntent Client Secret from Backend
      const setupIntentRes = await ApiService.getInstance().setupAddPaymentMethod();
      const {
        clientSecret,
      } = setupIntentRes;

      if (!clientSecret) throw new Error('Failed to create SetupIntent');

      // Step 2: Confirm Card Setup on Frontend
      const cardElement = elements.getElement(CardElement);
      const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
        payment_method: { card: cardElement! },
      });

      if (error) throw new Error(error.message);

      // Step 3: Send Payment Method ID to Backend to Attach
      const paymentMethodId = setupIntent.payment_method;
      if (!paymentMethodId) {
        // throw new Error('Failed to attach payment method');
        dispatch(addNotification({
          type: 'error',
          message: 'Failed to attach payment method',
        }));
      };
      try {
        await ApiService.getInstance().addPaymentMethod(paymentMethodId as string);
        dispatch(addNotification({
          type: 'success',
          message: 'Card added successfully!',
        }));
        onCardAdded();
      } catch (error: any) {
        dispatch(addNotification({
          type: 'error',
          message: error.message,
        }));
      }

    } catch (error: any) {
      dispatch(addNotification({
        type: 'error',
        message: error.message,
      }));
    }

    setLoading(false);
  };

  return (
    <Container sx={{ maxWidth: '500px', py: 4 }}>
    <Typography variant="overline" gutterBottom>Add a Payment Method</Typography>
    <form onSubmit={handleSubmit}>
    <Box sx={{
      border: '1px solid #ccc',
      borderRadius: '4px',
      padding: '12px',
      backgroundColor: 'black',
    }}>
    <CardElement

    options={{
      style: {
        base: {
          backgroundColor: 'black',
          color: 'white',
          textTransform: 'uppercase',
          fontSize: '16px',
          '::placeholder': {
          },
        },
      },
    }}
    />
    </Box>
    <Button type="submit" variant="contained" color="primary" disabled={loading} sx={{ mt: 2 }}>
    {loading ? <CircularProgress size={24} /> : 'Add Card'}
    </Button>
    </form>
    </Container>
  );
};

export default AddCardForm;
