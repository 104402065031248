
import React from 'react';
import { Container, Box, Card, CardContent, Typography, Button, Grid, Avatar } from '@mui/material';
import Environment from '@web-client/services/environment';

const SubscribeTo: React.FC = () => {
  const creator = {
    name: 'Creator Name',
    email: 'creator@example.com',
    description: 'Support the creator by subscribing for exclusive content and perks.'
  };

  const subscriptionCost = 9.99;
  const subscriptionDescription = 'Monthly subscription for exclusive access to premium content.';

  const handleCheckoutClick = async () => {
    try {
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ creator, subscriptionCost })
      });
      const session = await response.json();

      const stripe = await Environment.getInstance().getStripePromise();
      if (stripe) {
        const { error } = await stripe.redirectToCheckout({ sessionId: session.id });
        if (error) console.error(error);
      }
    } catch (err) {
      console.error('Error during checkout', err);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Card>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Support {creator.name}
          </Typography>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <strong>Name:</strong> {creator.name}
              </Typography>
              <Typography variant="body2">
                <strong>Email:</strong> {creator.email}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                {creator.description}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6">
              Subscription Details
            </Typography>
            <Typography variant="body1">
              <strong>Cost:</strong> ${subscriptionCost.toFixed(2)} / month
            </Typography>
            <Typography variant="body2">
              {subscriptionDescription}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            onClick={handleCheckoutClick}
          >
            Subscribe Now
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
};

export default SubscribeTo;