import axios, { Axios, AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import Environment from './environment';
import { auth, FirebaseUserSerialized } from '../firebase';
import {typeCheck} from 'type-check';
import assert from 'assert';
import { ProductFeed, UserPricingSettings, UserWallet } from '@server/other/classes';
import { signInWithEmailAndPassword } from '@firebase/auth';
import { getAuth, onIdTokenChanged } from 'firebase/auth';
import {store} from '@web-client/state/store';
import { apiRequestFailed } from '@web-client/state/slices/notifications';

const env = Environment.getInstance();
const API_HOST = env.getApiHost();

export enum ProductUnitTypes {
  VIDEO = 'video',
  ARTICLE = 'article',
};

export type RequestWrapper<T> = {
  status: number | null | undefined,
  message: string | null | undefined,
  result: T | null | undefined,
  results: T[] | null | undefined,
  error: any | null | undefined,
}

export type LoginPayload = {
  firebaseUser: FirebaseUserSerialized,
  googleUser: User,
  customer: any,
}

export type FileUploadItem = {
  name: string,
  fileName: string,
  metadata: {
    size: string,
  },
  url: string,
}

export type ProductContent = {
  id?: string,
  title: string,
  type: ProductUnitTypes | null | undefined,
  value: string,
};

export type ProductContentGroup = {
  title: string,
  content: ProductContent[],
};

export type ProductDatum = {
  id?: string,
  title: string,
  imageUrl: string,
  isPublished: boolean,
  content: ProductContentGroup[],
  creatorEmail?: string,
  creator?: User,
  monthlyPrice?: number,
  coursePrice?: number,
  currency?: string,
  isPurchased?: boolean,
  isOwner?: boolean,
}

export type User = {
  uid: string,
  displayName: string,
  email: string,
  expiresIn: string,
  idToken: string,
  kind: string,
  localId: string,
  profilePicture: string,
  refreshToken: string,
  registered: boolean,
  photoURL?: string,
};

export type UpdateProfileParams = {
  // email: TBD
  displayName: string,
};

export interface StripeCharge {
  id: string;
  object: string; // "charge"
  amount: number;
  amount_captured: number;
  amount_refunded: number;
  application: string | null;
  application_fee: string | null;
  application_fee_amount: number | null;
  balance_transaction: string | null;
  billing_details: {
    address: {
      city: string | null;
      country: string | null;
      line1: string | null;
      line2: string | null;
      postal_code: string | null;
      state: string | null;
    };
    email: string | null;
    name: string | null;
    phone: string | null;
  };
  captured: boolean;
  created: number; // Timestamp
  currency: string;
  customer: string | null; // Customer ID
  description: string | null;
  destination: string | null;
  dispute: string | null;
  failure_code: string | null;
  failure_message: string | null;
  fraud_details: {
    user_report: string | null;
    stripe_report: string | null;
  };
  invoice: string | null;
  livemode: boolean;
  metadata: Record<string, string>;
  on_behalf_of: string | null;
  outcome: {
    network_status: string;
    reason: string | null;
    risk_level: string;
    risk_score: number;
    seller_message: string | null;
    type: string;
  } | null;
  paid: boolean;
  payment_intent: string | null;
  payment_method: string | null;
  payment_method_details: {
    type: string;
    card?: {
      brand: string;
      checks: {
        address_line1_check: string | null;
        address_postal_code_check: string | null;
        cvc_check: string | null;
      };
      country: string;
      exp_month: number;
      exp_year: number;
      fingerprint: string;
      funding: string;
      installments: string | null;
      last4: string;
      network: string;
      three_d_secure: string | null;
      wallet: string | null;
    };
  } | null;
  receipt_email: string | null;
  receipt_number: string | null;
  receipt_url: string | null;
  refunded: boolean;
  refunds: {
    object: string;
    data: Array<{
      id: string;
      amount: number;
      currency: string;
      created: number;
      reason: string | null;
      status: string;
    }>;
    has_more: boolean;
    total_count: number;
    url: string;
  };
  review: string | null;
  shipping: {
    address: {
      city: string | null;
      country: string | null;
      line1: string | null;
      line2: string | null;
      postal_code: string | null;
      state: string | null;
    };
    carrier: string | null;
    name: string | null;
    phone: string | null;
    tracking_number: string | null;
  } | null;
  source_transfer: string | null;
  statement_descriptor: string | null;
  statement_descriptor_suffix: string | null;
  status: string;
  transfer_data: {
    amount: number | null;
    destination: string | null;
  } | null;
  transfer_group: string | null;
}

export interface StripeChargeList {
  object: string; // "list"
  data: StripeCharge[];
  has_more: boolean;
  url: string;
}

const USER_TOKEN_KEY = 'idToken';

export function getStoredUserIdToken() {
  return window.localStorage.getItem(USER_TOKEN_KEY);
}

export function persistIdToken(idToken: string) {
  assert(typeCheck('String|null|undefined', idToken));
  window.localStorage.setItem(USER_TOKEN_KEY, idToken);
  setTimeout(() => {
    window.dispatchEvent(new Event('storage'));
  }, 0);
}

export function clearIdToken() {
  window.localStorage.removeItem(USER_TOKEN_KEY);
  setTimeout(() => {
    window.dispatchEvent(new Event('storage'));
  }, 0);
}

class ApiService {

  private api: Axios;

  private failureListeners: Set<Function> = new Set([]);

  private static _instance: ApiService;

  private getBaseUrl() {
    return API_HOST;
  }

  private async getUserIdToken() {
    // if (!auth.currentUser) {
    return await getStoredUserIdToken();
    // }
    // return await auth.currentUser?.getIdToken();
  }

  private async createAuthHeaders(): Promise<Record<string, string>> {
    const defaultHeaders = await this.createHeaders();
    const idToken = await this.getUserIdToken();
    return idToken ? {
      [env.getAuthTokenHeaderKey()]: idToken,
    } : {};
  }

  private aborter = new AbortController();

  private constructor() {
    this.api = axios.create({
      baseURL: this.getBaseUrl(),
      // timeout: 3000,
      // headers: {
      //   'X-Custom-Header': this.getUserIdToken();
      // }
      signal: this.aborter.signal,
    });
    axiosRetry(this.api as AxiosInstance, {
      retries: 5,
      retryDelay: axiosRetry.exponentialDelay,
      retryCondition: async (error) => {
        console.log('shall we retry?');
        this.emitFailure(error);
        if (!(error && error.response)) {
          return false;
        }
        // Ignore aborts.
        if (error?.message && error?.message.indexOf('Abort') !== -1) {
          return false;
        }
        if (error?.response?.status === 400) {
          return false;
        }
        if (error?.response?.status === 404) {
          return false;
        }
        if (error?.response?.status === 429) {
          return false;
        }
        if (error?.response?.status === 401) {
          return false;
          const auth = getAuth();
          const user = auth.currentUser;
          if (user) {
            await new Promise<void>((resolve) => {
              onIdTokenChanged(auth, (user) => {
                if (user) {
                  resolve();
                }
              });
            });
            return true;
          }
          return false;
        }
        // if retry condition is not specified, by default idempotent requests are retried
        return error.response.status === 503;
      },
    });
    // attach interceptors
    this.api.interceptors.response.use((response) => response,
    async (error) => {
      this.emitFailure(error);
      store.dispatch(apiRequestFailed(error));
      console.error('interceptor', { error });
      throw error;
    });
  }

  public static getInstance() {
    return this._instance || (this._instance = new this());
  }

  public abortAll() {
    this.aborter.abort();
  }

  public onFailure(callback: Function) {
    console.log('failure listeners', this.failureListeners);
    const currentListeners = Array.from(this.failureListeners)
    .filter((v) => !!v);

    const listenersAsStrings = currentListeners.map((fn) => fn?.toString());
    const listenerNames = currentListeners.map((fn) => fn.name);

    const uniqueFnStrings = new Set(listenersAsStrings);
    const uniqueFnNames = new Set(listenerNames.filter((v) => !!v));

    const newFnAsString = callback.toString && callback.toString();
    const newFnName = callback.name ?? null;

    if (!this.failureListeners.has(callback) &&
    !uniqueFnStrings.has(newFnAsString) &&
    !(newFnName && uniqueFnNames.has(newFnName))
  ) {
    this.failureListeners.add(callback);
  }
}

public removeFailureListener(callback: Function) {
  this.failureListeners.delete(callback);
}

private emitFailure(error?: Error) {
  if (error instanceof AxiosError) {
    if (error.response?.status !== 401) {
      return;
    }
  }
  console.log('emitting failures', this.failureListeners)
  this.failureListeners.forEach((callback) => {
    callback && callback(error);
  });
  const filteredListeners = Array.from(this.failureListeners)
  .filter((v) => !!v);
  this.failureListeners = new Set(filteredListeners);
}

private async createHeaders() {
  return Promise.resolve({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  });
}

private async handleRequest<T>(
    request: Promise<AxiosResponse<any, any>>): Promise<RequestWrapper<T>> {
  try {
    const response = await request;
    return response.data;
  } catch (error: any) {
    console.error({ error });
    throw error?.response?.data ?? error;
  }
}
  async loginWithCredentials(
    email: string,
    password: string
  ): Promise<LoginPayload> {
    const payload = await this.handleRequest<LoginPayload>(
      this.api.post(`${API_HOST}/api/v1/account/login`, { email, password })
    );
    const firebaseLoginResult =
      await signInWithEmailAndPassword(auth, email, password);
    console.log({ firebaseLoginResult });
    return payload.result as LoginPayload;
  }

  async getUserInfo(): Promise<User> {
    const headers = await this.createAuthHeaders();
    const payload = await this.handleRequest<User>(
      this.api.get(`${API_HOST}/api/v1/account`, { headers })
    );
    return payload.result as User;
  }

  async registerWithCredentials(email: string, password: string): Promise<User> {
    const payload = await this.handleRequest<User>(
      this.api.post(`${API_HOST}/api/v1/account/create`, { email, password })
    );
    return payload.result as User;
  }

  async updateProfile(fields: UpdateProfileParams): Promise<User> {
    const headers = await this.createAuthHeaders();
    const payload = await this.handleRequest<User>(
      this.api.post(`${API_HOST}/api/v1/account/update`, fields, { headers })
    );
    return payload.result as User;
  }

  async getProfile(): Promise<User> {
    const headers = await this.createAuthHeaders();
    const payload = await this.handleRequest<User>(
      this.api.get(`${API_HOST}/api/v1/account`, { headers })
    );
    return payload.result as User;
  }

  async getAllCreations(): Promise<ProductDatum[]> {
    const headers = await this.createAuthHeaders();
    const payload = await this.handleRequest<ProductDatum>(
      this.api.get(`${API_HOST}/api/v1/products`, { headers })
    );
    return payload.results as ProductDatum[];
  }

  async getProductById(productId: string): Promise<ProductDatum> {
    const headers = await this.createAuthHeaders();
    const payload = await this.handleRequest<ProductDatum>(
      this.api.get(`${API_HOST}/api/v1/products/${productId}`, { headers })
    );
    return payload.result as ProductDatum;
  }

  async getProductFeed(): Promise<ProductFeed> {
    const headers = await this.createAuthHeaders();
    const payload = await this.handleRequest<ProductFeed>(
      this.api.get(`${API_HOST}/api/v1/products/feed`, { headers })
    );
    return payload.result as ProductFeed;
  }

  async createProduct(product: ProductDatum): Promise<ProductDatum> {
    const headers = await this.createAuthHeaders();
    const payload = await this.handleRequest<ProductDatum>(
      this.api.post(`${API_HOST}/api/v1/products/create`, product, { headers })
    );
    return payload.result as ProductDatum;
  }

  async editProduct(product: ProductDatum): Promise<ProductDatum> {
    const headers = await this.createAuthHeaders();
    const payload = await this.handleRequest<ProductDatum>(
      this.api.post(`${API_HOST}/api/v1/products/edit`, product, { headers })
    );
    return payload.result as ProductDatum;
  }

  async getVideoInfo(videoId: string): Promise<any> {
    const payload = await this.handleRequest<any>(
      this.api.get(`${API_HOST}/api/v1/videos/${videoId}`)
    );
    return payload.result;
  }

  async getUploadsForToken(): Promise<FileUploadItem[]> {
    const headers = await this.createAuthHeaders();
    const payload = await this.handleRequest<FileUploadItem>(
      this.api.get(`${API_HOST}/api/v1/uploads`, { headers })
    );
    return payload.results as FileUploadItem[];
  }

  async getOrderHistory() {
    const headers = await this.createAuthHeaders();
    const payload = await this.handleRequest<StripeCharge>(
      this.api.get(`${API_HOST}/api/v1/orders`, { headers }));
    console.log({data: payload});
    const results = payload.results;
    console.log('getOrderHistory: the results are here:', { results });
    return results;
  }

  async getLibrary() {
    const headers = await this.createAuthHeaders();
    const payload = await this.handleRequest<any>(
      this.api.get(`${API_HOST}/api/v1/library`, { headers })
    );
    return payload.results;
  }

  async getUserWallet() {
    const headers = await this.createAuthHeaders();
    const payload = await this.handleRequest<UserWallet>(
      this.api.post(`${API_HOST}/api/v1/account/wallet`, {}, { headers })
    );
    return (payload.result as UserWallet) ?? null;
  }

  async setupAddPaymentMethod() {
    const headers = await this.createAuthHeaders();
    const payload = await this.handleRequest<any>(
      this.api.post(`${API_HOST}/api/v1/account/wallet/paymentMethod/setup`, {

      }, { headers })
    );
    return payload.result;
  }

  async addPaymentMethod(paymentMethodId: string) {
    assert(typeCheck('String', paymentMethodId), 'paymentMethodId must be a string');
    const headers = await this.createAuthHeaders();
    const payload = await this.handleRequest<any>(
      this.api.post(`${API_HOST}/api/v1/account/wallet/paymentMethod`, {
        paymentMethodId,
      }, { headers })
    );
    return payload.result;
  }

  async setDefaultPaymentMethod(paymentMethodId: string) {
    assert(typeCheck('String', paymentMethodId), 'paymentMethodId must be a string');
    const headers = await this.createAuthHeaders();
    const payload = await this.handleRequest<any>(
      this.api.post(`${API_HOST}/api/v1/account/wallet/paymentMethod/default`, {
        paymentMethodId,
      }, { headers })
    );
    return payload.result;
  }

  async removePaymentMethod(paymentMethodId: string) {
    assert(typeCheck('String', paymentMethodId), 'paymentMethodId must be a string');
    const headers = await this.createAuthHeaders();
    const payload = await this.handleRequest<any>(
      this.api.delete(`${API_HOST}/api/v1/account/wallet/paymentMethod/${paymentMethodId}`, { headers })
    );
    return payload.result;
  }

  async getPaymentMethods() {
    const headers = await this.createAuthHeaders();
    const payload = await this.handleRequest<any>(
      this.api.get(`${API_HOST}/api/v1/account/wallet/paymentMethods`, { headers })
    );
    return payload.results;
  }

  async updateUserPricingSettings(settings: UserPricingSettings) {
    const headers = await this.createAuthHeaders();
    const payload = await this.handleRequest<any>(
      this.api.post(`${API_HOST}/api/v1/account/pricingSettings`, settings, { headers })
    );
    return payload.result;
  }

  async getUserPricingSettings() {
    const headers = await this.createAuthHeaders();
    const payload = await this.handleRequest<any>(
      this.api.get(`${API_HOST}/api/v1/account/pricingSettings`, { headers })
    );
    return payload.result;
  }

}
export default ApiService;