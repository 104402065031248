import { loadStripe, Stripe } from "@stripe/stripe-js";

class Environment {

  private static _instance: Environment;

  private static _stripePromise: Promise<Stripe|null> | null = null;

  private constructor() { }

  public static getInstance() {
    return this._instance || (this._instance = new this());
  }

  isTest() {
    return /test/.test(process.env.NODE_ENV);
  }

  isDev() {
    const host = window.location.hostname;
    return host.indexOf('localhost') !== -1;
  }

  getCurrentHost(): string {
    const host = window.location.hostname;
    const protocol = window.location.protocol;
    if (this.isDev()) {
      return `http://${host}:3000`;
    }
    return `${protocol}//${host}`;
  }

  getApiHost(): string {
    const host = window.location.hostname;
    const protocol = window.location.protocol;
    // const port = window.location.port;
    if (this.isDev()) {
      return `http://${host}:3001`;
    }
    return `${protocol}//${host}`;
  }

  getStripeApiKey(): string {
    if (this.isDev()) {
      return 'pk_test_51Oypl5Rx6Cbe46a06B0I4h6H0EWFx34JFhPeZSapwKQ02HJ73VNzxCIF9jmg68JK2e53xIwTrCgq6n2ZUvul70fc000pjnvsym'

    }
    return 'pk_live_51Oypl5Rx6Cbe46a0cbZbLU3f2yeL39IYHGX1oIMMPZqc5WlMBnuB4Ll7T0s5zSBEUdJqkfXfSOQc2QlNWvxnMtzl0053f2Ehbn';
  }

  getStripePromise() {
    if (Environment._stripePromise) {
      return Environment._stripePromise;
    }
    return loadStripe(this.getStripeApiKey());
  }

  getAuthTokenHeaderKey(): string {
    return 'X-Auth-Token';
  }
}

export default Environment;