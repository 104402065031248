import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import ApiService, { FileUploadItem, UpdateProfileParams, StripeCharge, getStoredUserIdToken, persistIdToken, clearIdToken, User } from '../../services/ApiService';
import assert from 'assert';
import {typeCheck} from 'type-check';
import { auth, FirebaseUserSerialized } from '../../firebase';
import { getAuth } from '@firebase/auth';
import { UserPricingSettings, UserWallet } from '@server/other/classes';
import exp from 'constants';


export interface UserSliceState {
  idToken: string | null,
  isLoggingIn: boolean,
  isRegistering: boolean,
  isGettingProfile: boolean,
  isUpdatingProfile: boolean,
  isGettingUploads: boolean,
  isGettingOrderHistory: boolean,
  isGettingWallet: boolean,
  isAddingPaymentMethod: boolean,
  isRemovingPaymentMethod: boolean,
  isSettingDefaultPaymentMethod: boolean,
  isUpdatingPricingSettings: boolean,
  isGettingPricingSettings: boolean,
  errors: string[],
  user: User | null,
  uploads: FileUploadItem[],
  orderHistory: StripeCharge[],
  wallet: UserWallet | null,
  pricingSettings: UserPricingSettings | null,
}

const initialState: UserSliceState = {
  idToken: getStoredUserIdToken(),
  isLoggingIn: false,
  isRegistering: false,
  isGettingProfile: false,
  isUpdatingProfile: false,
  isGettingUploads: false,
  isGettingOrderHistory: false,
  isGettingWallet: false,
  isAddingPaymentMethod: false,
  isRemovingPaymentMethod: false,
  isSettingDefaultPaymentMethod: false,
  isUpdatingPricingSettings: false,
  isGettingPricingSettings: false,
  errors: [],
  user: null,
  uploads: [],
  orderHistory: [],
  wallet: null,
  pricingSettings: null,
};

type LoginCredentials = {
  email: string, password: string
};

export const refreshIdToken = createAsyncThunk(
  'users/refreshIdToken', async () => {
    console.log('refresh token dispatched, trying to get new...');
    return await getAuth().currentUser?.getIdToken(true);
});

export const registerWithCredentials = createAsyncThunk(
  'users/registerWithCredentials', async (creds: LoginCredentials) => {
    const { email, password } = creds;
    const result = await ApiService.getInstance()
      .registerWithCredentials(email, password);
    return result;
  });

export const loginWithCredentials = createAsyncThunk(
  'users/loginWithCredentials', async (creds: LoginCredentials) => {
    const { email, password } = creds;
    return await ApiService.getInstance()
      .loginWithCredentials(email, password);
});

export const getProfile = createAsyncThunk(
  'users/getProfileForToken', async () => {
    const result = await ApiService.getInstance()
      .getProfile();
    return result;
});

export const getUserWallet = createAsyncThunk(
  'users/getUserWallet', async () => {
    const result = await ApiService.getInstance()
      .getUserWallet();
    return result;
});

export const getUploads = createAsyncThunk(
  'users/getUploads', async () => {
    const result = await ApiService.getInstance()
      .getUploadsForToken();
    return result;
});

export const updateProfile = createAsyncThunk(
  'users/updateProfileForToken', async (params: {
    fields: UpdateProfileParams,
  }) => {
    const {fields} = params;
    const result = await ApiService.getInstance()
      .updateProfile(fields);
    return result;
});

export const getOrderHistory = createAsyncThunk(
  'users/getOrderHistory', async () => {
    const result = await ApiService.getInstance()
      .getOrderHistory();
    return result;
});

export const setDefaultPaymentMethod = createAsyncThunk(
  'users/setDefaultPaymentMethod', async (params: {
    paymentMethodId: string,
  }) => {
    const {paymentMethodId} = params;
    const result = await ApiService.getInstance()
      .setDefaultPaymentMethod(paymentMethodId);
    return result;
});

export const removePaymentMethod = createAsyncThunk(
  'users/removePaymentMethod', async (params: {
    paymentMethodId: string,
  }) => {
    const {paymentMethodId} = params;
    const result = await ApiService.getInstance()
      .removePaymentMethod(paymentMethodId);
    return result;
});

export const addPaymentMethod = createAsyncThunk(
  'users/addPaymentMethod', async (params: {
    paymentMethodId: string,
  }) => {
    const {paymentMethodId} = params;
    const result = await ApiService.getInstance()
      .addPaymentMethod(paymentMethodId);
    return result;
});

export const updateUserPricingSettings = createAsyncThunk(
  'users/updateUserPricingSettings', async (params: UserPricingSettings) => {
    const result = await ApiService.getInstance()
      .updateUserPricingSettings(params);
    return result;
});

export const getUserPricingSettings = createAsyncThunk(
  'users/getUserPricingSettings', async () => {
    const result = await ApiService.getInstance()
      .getUserPricingSettings();
    return result;
});

export const logout = createAsyncThunk('users/logout', async () => {
  await auth.signOut();
  clearIdToken();
  return
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: (create) => ({
    setIdToken: create.reducer((state, action: PayloadAction<string|null>) => {
      state.idToken = action.payload;
      persistIdToken(action.payload ?? '');
    }),
    clearErrors: create.reducer((state) => {
      state.errors.length = 0;
    }),
    setUser: create.reducer((
      state, action: PayloadAction<User | FirebaseUserSerialized | null>
    ) => {
      state.user = action.payload as User;
    }),
  }),
  extraReducers: (builder) => {

    // ------------ Refresh Token ---------------
    builder.addCase(refreshIdToken.pending, (state, action) => {
    }).addCase(refreshIdToken.fulfilled, (state, action) => {
      const newToken = action.payload;
      if (newToken != null) {
        state.idToken = newToken;
      }
      persistIdToken(newToken ?? '');
    }).addCase(refreshIdToken.rejected, (state, action) => {
      // TODO(benedictchen): Perhaps show a global error?
    });

    // ------------ Login ---------------
    builder.addCase(loginWithCredentials.pending, (state, action) => {
      state.isLoggingIn = true;
      state.errors.length = 0;
    }).addCase(loginWithCredentials.fulfilled, (state, action) => {
      const user = action.payload.googleUser;
      console.log({user})
      state.isLoggingIn = false;
      const idToken: string = action.payload.googleUser?.idToken;
      if (!typeCheck('String', idToken)) {
        throw new TypeError(`ID Token: ${idToken}`);
      }
      state.idToken = idToken;
      state.user = user
      persistIdToken(idToken ?? '');
    }).addCase(loginWithCredentials.rejected, (state, action) => {
      state.isLoggingIn = false;
      state.errors.push(action.error?.message ?? 'Unknown Error');
    });

    // ------------ Register ---------------
     builder.addCase(registerWithCredentials.pending, (state, action) => {
       state.isRegistering = true;
       state.errors.length = 0;
    }).addCase(registerWithCredentials.fulfilled, (state, action) => {
      state.isRegistering = false;
      const idToken: string = action.payload.idToken;
      if (!typeCheck('String', idToken)) {
        throw new TypeError(idToken);
      }
      state.idToken = idToken;
      persistIdToken(idToken ?? '');
    }).addCase(registerWithCredentials.rejected, (state, action) => {
      state.isRegistering = false;
      console.log('fail', action.payload);
      // TODO(benedictchen): We need to use error reporting system here.
      console.error({
        error: action.error
      });
      state.errors.push(action.error?.message ?? 'Unknown Error');
    });
    // ------------ Logout ---------------
    builder.addCase(logout.pending, (state, action) => {
      state.isLoggingIn = false;
      state.isRegistering = false;
      state.errors.length = 0;
    }).addCase(logout.fulfilled, (state, action) => {
      state.idToken = null;
    }).addCase(logout.rejected, (state, action) => {
      // Do nothing if logout fails?
    });

    // ------------ Get Profile ---------------
    builder.addCase(getProfile.pending, (state, action) => {
      state.isGettingProfile = true;
    }).addCase(getProfile.fulfilled, (state, action) => {
      state.isGettingProfile = false;
    }).addCase(getProfile.rejected, (state, action) => {
      state.isGettingProfile = false;
    });

    // ------------ Update Profile ---------------
    builder.addCase(updateProfile.pending, (state, action) => {
      state.isUpdatingProfile = true;
    }).addCase(updateProfile.fulfilled, (state, action) => {
      state.isUpdatingProfile = false;
    }).addCase(updateProfile.rejected, (state, action) => {
      state.isUpdatingProfile = false;
    });

    // ------------ Get Uploads ---------------
    builder.addCase(getUploads.pending, (state, action) => {
      state.isGettingUploads = true;
      state.uploads = [];
    }).addCase(getUploads.fulfilled, (state, action) => {
      state.isGettingUploads = false;
      state.uploads = action.payload;
    }).addCase(getUploads.rejected, (state, action) => {
      state.isGettingUploads = false;
      state.uploads = [];
    });
    // ------------ Get Order History ---------------
    builder.addCase(getOrderHistory.pending, (state, action) => {
      state.isGettingOrderHistory = true;
      state.orderHistory = [];
    }).addCase(getOrderHistory.fulfilled, (state, action) => {
      state.isGettingOrderHistory = false;
      state.orderHistory = action.payload ?? [];
    }).addCase(getOrderHistory.rejected, (state, action) => {
      state.isGettingOrderHistory = false;
      state.orderHistory = [];
    });
    // ------------ Get User Wallet ---------------
    builder.addCase(getUserWallet.pending, (state, action) => {
      state.isGettingWallet = true;
      state.wallet = null;
    }).addCase(getUserWallet.fulfilled, (state, action) => {
      state.isGettingWallet = false;
      state.wallet = action.payload;
    }).addCase(getUserWallet.rejected, (state, action) => {
      state.isGettingWallet = false;
      state.wallet = null;
    });
    // ------------ Set Default Payment Method ---------------
    builder.addCase(setDefaultPaymentMethod.pending, (state, action) => {
      state.isSettingDefaultPaymentMethod = true;
    }).addCase(setDefaultPaymentMethod.fulfilled, (state, action) => {
      state.isSettingDefaultPaymentMethod = false;
    }).addCase(setDefaultPaymentMethod.rejected, (state, action) => {
      state.isSettingDefaultPaymentMethod = false;
    });
    // ------------ Remove Payment Method ---------------
    builder.addCase(removePaymentMethod.pending, (state, action) => {
      state.isRemovingPaymentMethod = true;
    }).addCase(removePaymentMethod.fulfilled, (state, action) => {
      state.isRemovingPaymentMethod = false;
    }).addCase(removePaymentMethod.rejected, (state, action) => {
      state.isRemovingPaymentMethod = false;
    });
    // ------------ Add Payment Method ---------------
    builder.addCase(addPaymentMethod.pending, (state, action) => {
      state.isAddingPaymentMethod = true;
    }).addCase(addPaymentMethod.fulfilled, (state, action) => {
      state.isAddingPaymentMethod = false;
    }).addCase(addPaymentMethod.rejected, (state, action) => {
      state.isAddingPaymentMethod = false;
    });
    // ------------ Set User Settings ---------------
    builder.addCase(updateUserPricingSettings.pending, (state, action) => {
      state.isUpdatingPricingSettings = true;
    }).addCase(updateUserPricingSettings.fulfilled, (state, action) => {
      state.isUpdatingPricingSettings = false;
    }).addCase(updateUserPricingSettings.rejected, (state, action) => {
      state.isUpdatingPricingSettings = false;
    });
    // ------------ Get User Settings ---------------
    builder.addCase(getUserPricingSettings.pending, (state, action) => {
      state.isGettingPricingSettings = true;
    }).addCase(getUserPricingSettings.fulfilled, (state, action) => {
      state.isGettingPricingSettings = false;
      state.pricingSettings = action.payload;
    }).addCase(getUserPricingSettings.rejected, (state, action) => {
      state.isGettingPricingSettings = false;
      state.pricingSettings = null;
    });

  },
  selectors: {
    selectIdToken: (state) => state.idToken,
    isLoggingIn: (state) => state.isLoggingIn,
    isRegistering: (state) => state.isRegistering,
    isGettingProfile: (state) => state.isGettingProfile,
    isUpdatingProfile: (state) => state.isUpdatingProfile,
    isGettingWallet: (state) => state.isGettingWallet,
    selectErrors: (state) => state.errors,
    selectUser: (state) => state.user,
    selectUploads: (state) => state.uploads,
    selectIsGettingUploads: (state) => state.isGettingUploads,
    selectOrders: (state) => state.orderHistory,
    isGettingOrderHistory: (state) => state.isGettingOrderHistory,
    selectWallet: (state) => state.wallet,
    selectIsStripeAccountDisabled: (state) => {
      return Array.isArray(state.wallet?.stripeConnectAccount?.requirements?.disabled_reason) ||
        state.wallet?.stripeConnectAccount?.payouts_enabled === false;
    },
    selectIsAddingPaymentMethod: (state) => state.isAddingPaymentMethod,
    selectIsRemovingPaymentMethod: (state) => state.isRemovingPaymentMethod,
    selectIsSettingDefaultPaymentMethod: (state) => state.isSettingDefaultPaymentMethod,
    selectPricingSettings: (state) => state.pricingSettings,
    selectIsGettingPricingSettings: (state) => state.isGettingPricingSettings,
  }
});

// Actions
export const {
  setIdToken,
  clearErrors,
} = userSlice.actions;

export const {
  selectUploads,
  selectIdToken,
  selectUser,
  selectIsGettingUploads,
  selectWallet,
  selectIsStripeAccountDisabled,
  selectIsAddingPaymentMethod,
  selectIsRemovingPaymentMethod,
  selectIsSettingDefaultPaymentMethod,
  selectPricingSettings,
  selectIsGettingPricingSettings,
} = userSlice.selectors;

export default userSlice.reducer;