import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, useAppSelector } from '@web-client/state/store';
import {
  removeNotification,
  selectNotifications,
} from '@web-client/state/slices/notifications';
import { Snackbar, Alert } from '@mui/material';

const Notifications = () => {
  const dispatch = useDispatch();
  const notifications = useAppSelector(selectNotifications);

  useEffect(() => {
    notifications.forEach(notification => {
      setTimeout(() => {
        dispatch(removeNotification(notification.id));
      }, 5000); // Auto-dismiss after 5 seconds
    });
  }, [notifications, dispatch]);

  return (
    <>
      {Array.isArray(notifications) && notifications.map(notification => (
        <Snackbar
          key={notification.id}
          open={true}
          autoHideDuration={8000}
          onClose={() => dispatch(removeNotification(notification.id))}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert
            severity={notification.type}
            onClose={() => dispatch(removeNotification(notification.id))}
            sx={{
              // width: 'calc(100vw - 32px)',
              width: '100%',
              boxShadow: '2px 2px 30px magenta',
            }}
          >
            {typeof notification.message === 'string' ? notification.message : JSON.stringify(notification.message)}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};

export default Notifications;
