import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { RootState, AppDispatch } from '../store';
// import { fetchLearningCourses, setFilter } from '../store/coursesSlice';
import {
  fetchLearningCourses, setFilter,
} from '@web-client/state/slices/learningCourses';

import {
  Box,
  Typography,
  Card,
  CardContent,
  LinearProgress,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
} from '@mui/material';
import { AppDispatch, RootState } from '@web-client/state/store';
import { useNavigate } from 'react-router-dom';
import Paths from '@web-client/paths';

interface LearningCoursesProps {
  userId: string; // User ID to fetch courses for
}

const LearningCourses: React.FC<LearningCoursesProps> = ({ userId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { courses, status, error, filter } = useSelector((state: RootState) => state.learningCourses);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchLearningCourses());
  }, [dispatch, userId]);

  // Filter courses based on the selected filter
  const filteredCourses = filter === 'all' ? courses : courses.filter((course) => course.status === filter);

  if (status === 'loading') {
    return (<LinearProgress variant='indeterminate'/>);
  }

  if (status === 'failed') {
    return (
      <Typography color="error" align="center">
      {error || 'Failed to load courses'}
      </Typography>
    );
  }

  return (
    <Box sx={{ padding: 10 }}>
    <Typography variant="h1" className="gradient-text" gutterBottom>
    My Library
    </Typography>

    {/* Filter Dropdown */}
    <FormControl style={{ marginBottom: '16px' }}>
    <Select
    title='Filter'
    value={filter}
    onChange={(e) => dispatch(setFilter(e.target.value as 'all' | 'purchased' | 'subscribed'))}
    >
    <MenuItem value="all">All</MenuItem>
    <MenuItem value="purchased">Purchased</MenuItem>
    <MenuItem value="subscribed">Subscribed</MenuItem>
    </Select>
    </FormControl>

    <Grid container spacing={3}>
    {filteredCourses.length === 0 && (
      <Grid item xs={12} sm={12} md={12}>
      <Paper>
      <CardContent>
      <Typography variant="h6" gutterBottom>
      Nothing here yet.
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
      Purchase or subscribe to courses to see them here.
      </Typography>
      </CardContent>
      </Paper>
      </Grid>
    )}
    {filteredCourses.map((course) => (
      <Grid item xs={12} sm={6} md={4} key={course.id}>
      <Card
      sx={{
        '&:hover': {
          boxShadow: 6,
          cursor: 'pointer',
        },
      }}
      onClick={() => {
        console.log('Clicked on course', course.id)
        navigate(Paths.PRODUCT_DETAILS
          .replace(':productId', course.id ?? ''));
        }}
        >
        <CardContent>
        <Typography variant="h6" gutterBottom>
        {course.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
        {course.description}
        </Typography>
        <Typography variant="body2" gutterBottom>
        Progress: {course.activity?.progress ?? 0}%
        </Typography>
        <LinearProgress variant="determinate" value={course.activity?.progress ?? 0} />
        </CardContent>
        </Card>
        </Grid>
      ))}
      </Grid>
      </Box>
    );
  };


  export const Library = () => {
    return (
      <Box>
      <LearningCourses userId="1" />
      </Box>
    );
  }

  export default Library;
