import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Grid, IconButton, ImageList, ImageListItem, Paper, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { faker } from '@faker-js/faker';
import Paths from '../../paths';
import { useTheme } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ThemedPaper from '../core/ThemedPaper';
import { useAppDispatch, useAppSelector } from '../../state/store';
import {
  getProductFeed,
  selectFeed,
} from '../../state/slices/products';
import { selectIdToken } from '../../state/slices/user';
import { useCallback, useEffect } from 'react';
import { debounce } from 'lodash';


function getRandomProductImage() {
  const randomNumber = Math.floor(Math.random() * 1000);
  return `https://picsum.photos/seed/${randomNumber}/300/150`;
}

// const itemData = Array(30).fill(null).map((_, index) => {
//   const randomNumber = Math.floor(Math.random() * 1000);
//   return {
//     id: index,
//     img: `https://picsum.photos/seed/${randomNumber + index + 1}/300/150`,
//     title: `${faker.commerce.productName()} Course`,
//     user: `@${faker.person.firstName()}${index}`,
//     description: faker.commerce.productDescription(),
//   };
// });

export function ProductListing() {

  const dispatch = useAppDispatch();
  const idToken = useAppSelector(selectIdToken);
  const feed = useAppSelector(selectFeed);

  const getFeed = debounce(useCallback((idToken: string) => {
      dispatch(getProductFeed(idToken ?? ''))
  }, [
    dispatch,
  ]));

  const navigate = useNavigate();
  const theme = useTheme();
  const activeColorA = theme.palette.info.main;
  const activeColorB = theme.palette.action.hover;

  useEffect(() => {
    getFeed(idToken ?? '');
  }, [idToken]);

  // const [hoveredItem, setHoveredItem] = useState<number|null>(null);

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <>
    <ThemedPaper elevation={10}
    sx={{ mb: 3, p: 1, width: '100%' }}>
    <Typography variant='overline'>
    New Releases
    {/* <ArrowForwardIosIcon
    sx={{ height: 10, transform: 'translateY(-2px)' }}
  /> */}
  </Typography>
  </ThemedPaper>
  <ImageList sx={{ width: '100%', height: '100%' }}
  cols={isLargeScreen ? 4 : 1} >
  {(feed?.newReleases ?? []).map((item, index) => {
    const redirectToItemDetails = () => {
      const productDetailsPath = Paths.PRODUCT_DETAILS
      .replace(':productId', item.id);
      navigate(productDetailsPath);
    };
    return (
      <ImageListItem key={index} sx={{ margin: 3 }}>
      <Card
      sx={{
        cursor: 'pointer',
        userSelect: 'none',
        transition: 'all 1.5s',
        '&:hover': {
          backgroundColor: activeColorB,
        },
      }}
      onClick={redirectToItemDetails}
      >
      <CardHeader
      avatar={
        <Avatar src={item.creator?.photoURL}>
        {item.creator?.displayName?.at(1)}
        </Avatar>
      }
      title={item.title}
      subheader={item.creator?.displayName}
      />
      <CardMedia
      component='img'
      height='200'
      image={getRandomProductImage()}
      alt={item.title}
      />
      {/* <CardContent>
      <Typography variant='body2' color='text.secondary'>
      {item.description}
      </Typography>
      </CardContent> */}
      <CardActions sx={{ display: 'none'}}
        onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}>
      <Grid container
      spacing={1}
      direction='row'
      justifyContent='center'
      alignItems='center'>
      <Grid item>
      <Tooltip title='Buy Now' arrow placement='top'>
      <Button variant='contained' size='small'
      color='secondary'
      aria-label='Buy Now'>
      <ShoppingCartIcon sx={{mr: 1}} /> $9.67

      </Button>
      </Tooltip>
      </Grid>
      <Grid item>
      <Tooltip title='Subscribe' arrow placement='top'>
      <Button variant='contained' size='small'
      color='primary'
      aria-label='Subscribe'>
      <PersonAddIcon sx={{mr: 1}}/>$29.67 <sub>/ Month</sub>
      </Button>
      </Tooltip>
      </Grid>
      <Grid item>
      <Tooltip title='See Details' arrow placement='top'>
      <Button variant='contained' size='small'
      color='success'
      aria-label='See Details'
      onClick={redirectToItemDetails}>
      Details <ArrowForwardIosIcon />
      </Button>
      </Tooltip>
      </Grid>
      </Grid>
      </CardActions>
      </Card>
      </ImageListItem>

    )})}
    </ImageList>
    </>
  );
}


export default ProductListing;